import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-elem-dateinput',
  templateUrl: './elem-dateinput.component.html',
  styleUrls: ['./elem-dateinput.component.css']
})
export class ElemDateinputComponent implements OnInit, AfterViewInit {
  @Input() dateText: string;
  @Output() dateTextChange = new EventEmitter<string>();


  abId: string;
  abIdCall: string;
  adInput: string;

  constructor() {
    this.abId = this.gedRandomId();
    this.abIdCall = '#' + this.abId;
    this.adInput = 'inp' + this.abId;
  }

  ngAfterViewInit() {



    $(this.abIdCall).calendar({
      type: 'date', formatter: {
        date: function (date, settings) {
          const year = date.getFullYear();
          let month = date.getMonth() + 1;
          let day = date.getDate();
          if (month < 10) {
            month = '0' + month;
          }
          if (day < 10) {
            day = '0' + day;
          }

          return day + '.' + month + '.' + year;
        }
      },
    });


  }

  ngOnInit() {

  }

  updateDate(value: string) {

  
    this.dateTextChange.emit(value);
  }

  gedRandomId() {
    const a = Math.floor(Math.random() * 3000) + 1;
    return 'modalUIComponent' + a.toString();
  }

}
