import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {BerechtigungsModell} from '../model/berechtigungs-modell';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private berechtigungen: BerechtigungsModell) {
  }

  canActivate(): boolean {
    return this.berechtigungen.darfPasswortAendern;
  }
}
