import { Component, OnInit } from '@angular/core';
import {ConfirmDialogServService} from '../../appbase/confirm-dialog/confirm-dialog-serv.service'
import {FormBuilder} from '@angular/forms'
import {Router} from '@angular/router'

@Component({
  selector: 'app-webentwickler',
  templateUrl: './webentwickler.component.html',
  styleUrls: ['./webentwickler.component.css']
})
export class WebentwicklerComponent implements OnInit {

  constructor(private cnf: ConfirmDialogServService, private formBuilder: FormBuilder,private router: Router) { }

  ngOnInit() {
  }



  bewerben() {
    this.router.navigate(['bewerben'],{queryParams: {id:'#30000', bez: 'Webentwickler'}});
  }
}
