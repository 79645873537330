import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menue-login',
  templateUrl: './menue-login.component.html',
  styleUrls: ['./menue-login.component.css']
})
export class MenueLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
