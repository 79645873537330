import {BrowserModule} from '@angular/platform-browser';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';

import {MenueComponent} from './oeffentlich/menue/menue.component';

import {DevToolsExtension, NgRedux, NgReduxModule} from '@angular-redux/store';
import {IAppState} from './interfaces/iapp-state';
import {NgReduxRouter, NgReduxRouterModule} from '@angular-redux/router';
import {rootReducer} from './reducers/combineReducers';

import {UserInformationComponent} from './appbase/user-information/user-information.component';
import {UserInformationActionService} from './actions/user-information-action.service';
import {HomeComponent} from './loginbereich/home/home.component';
import {routing} from './app-routing.module';
import {AppbaseModule} from './appbase/appbase.module';

import {AngularDateTimePickerModule} from 'angular2-datetimepicker';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {KarriereComponent} from './oeffentlich/karriere/karriere.component';
import {ImpressumComponent} from './oeffentlich/impressum/impressum.component';
import {DatenschutzComponent} from './oeffentlich/datenschutz/datenschutz.component';
import {WebentwicklerComponent} from './oeffentlich/webentwickler/webentwickler.component';
import {BewerbenComponent} from './oeffentlich/bewerben/bewerben.component';
import { MenueLoginComponent } from './loginbereich/menue-login/menue-login.component';
import { BewerbungComponent } from './loginbereich/bewerbung/bewerbung.component';
import {environment} from '../environments/environment'


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenueComponent,
    UserInformationComponent,
    KarriereComponent,
    ImpressumComponent,
    DatenschutzComponent,
    WebentwicklerComponent,
    BewerbenComponent,
    MenueLoginComponent,
    BewerbungComponent

  ],
  imports: [
    BrowserModule, FormsModule, routing, NgReduxModule, NgReduxRouterModule, AppbaseModule,
    ReactiveFormsModule, AngularDateTimePickerModule, BrowserAnimationsModule],
  providers: [
    FormBuilder,
    UserInformationActionService, NgReduxRouter,  !environment.production  ? DevToolsExtension: [],
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(
    ngRedux: NgRedux<IAppState>,
    ngReduxRouter: NgReduxRouter,
    devTools: DevToolsExtension) {

    ngRedux.configureStore(
      rootReducer,
      {},
      [],
      !environment.production ? [devTools.enhancer()] : []
    );

    ngReduxRouter.initialize();
  }
}

