import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef, EventEmitter,
  Injectable,
  Injector, Output
} from '@angular/core';
import {ConfirmDialogComponent} from "./confirm-dialog.component"
import {ConfirmEvent} from "./confirm-event"

@Injectable({
  providedIn: 'root'
})


export class ConfirmDialogServService {
  @Output() callbackEvent: EventEmitter<ConfirmEvent> = new EventEmitter<ConfirmEvent>();
  dialogComponentRef: ComponentRef<ConfirmDialogComponent>;
  cnfEvent:ConfirmEvent=new ConfirmEvent();
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) {
    
  }

  retYes:string="YES";
  retNo:string="NO";
  retAbort:string="ABORT";
  retOk:string="OK";
  
  appendDialogComponentToBody(){
    const  componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConfirmDialogComponent);
    const componentRef = componentFactory.create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    this.dialogComponentRef = componentRef;
    componentRef.instance.change.subscribe(val => {
      this.valueChange(val);

    });

  }
   valueChange(value:String) {

     if(value==this.retYes)
     {
       this.cnfEvent.isYes=true;
     }
     if(value==this.retNo)
     {
       this.cnfEvent.isNo=true;
     }
     if(value==this.retOk)
     {
       this.cnfEvent.isOk=true;
     }
     if(value==this.retAbort)
     {
       this.cnfEvent.isAbort=true;
     }
      this.callbackEvent.emit( this.cnfEvent);

  
     this.dialogComponentRef.instance.change.unsubscribe();
     this.appRef.detachView(this.dialogComponentRef.hostView);
     this.dialogComponentRef.destroy();
       
  }

  public open(pTitel:string,pText:string,pYes:boolean = false,pNo:boolean = false,pAbort:boolean = false,pOk:boolean = false, pObj:any=null) {
    this.callbackEvent= new EventEmitter<ConfirmEvent>();
    this.cnfEvent=new ConfirmEvent();
    this.appendDialogComponentToBody();
    this.dialogComponentRef.instance.titel=pTitel;
    this.dialogComponentRef.instance.text=pText;
    this.dialogComponentRef.instance.showYesLabel=pYes;
    this.dialogComponentRef.instance.showAbortLabel=pAbort;
    this.dialogComponentRef.instance.showNoLabel=pNo;
    this.dialogComponentRef.instance.showOkLabel=pOk;
    this.cnfEvent.obj=pObj;
    this.dialogComponentRef.instance.show();
  }

}
