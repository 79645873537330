import {Params} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable()
export class Queryparameter {
  private _params: Params;

  get params(): Params {
    return this._params;
  }
  set params(value: Params) {
    this._params = value;
  }
}
