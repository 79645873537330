import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numFormat'
})
export class StringNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (null == value) {
      return '';
    }
    const text: string = value.toString();
    const teile: string[] = text.split(',');
    if (teile.length === 1 || teile[0].length < 3) {
      return text;
    }
    return this.trenner(teile[0]) + ',' + teile[1];
  }

  trenner(zahl: string): string {
// Info: Die '' sind zwei Hochkommas
    if (zahl.length > 3) {
      const mod = zahl.length % 3;
      let output = (mod > 0 ? (zahl.substring(0, mod)) : '');
      for (let i = 0; i < Math.floor(zahl.length / 3); i++) {
        if ((mod === 0) && (i === 0)) {
          output += zahl.substring(mod + 3 * i, mod + 3 * i + 3);
        } else {
          // hier wird das Trennzeichen festgelegt mit '.'
          output += '.' + zahl.substring(mod + 3 * i, mod + 3 * i + 3);
        }
      }
      return (output);
    } else {
      return zahl;
    }
  }

}
