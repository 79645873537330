import * as si from 'seamless-immutable';

import {MenueState, UserInformationState} from '../interfaces/iapp-state';
import {IActionPayload} from '../interfaces/iaction-payload';
import {EnumAction} from '../enums/ssp-actions.enum';


const INITIAL_USER_INFORMATION_STATE: UserInformationState = si.from({

  isShow: false,


});

export function MenueReducer(state: MenueState = INITIAL_USER_INFORMATION_STATE,
                             action: IActionPayload<MenueState>): MenueState {

  switch (action.type) {

    case EnumAction.ShowMenue:
      return state
        .set('isShow', true)


  }
  return state;
}

