import {Injectable} from '@angular/core';
import {PasswordChangeModell} from '../model/password-change-modell';
import {Router} from '@angular/router';
import {IWerboHttpService} from './i-werbo-http.service';
import {IwBaseServices} from './iw-base-services';

@Injectable()
export class PwchangeService extends IwBaseServices {

  constructor(private http: IWerboHttpService, protected router: Router) {
    super();
  }

  public pwChange(pwChangeInfo: PasswordChangeModell) {
    return this.http.changePasswortServicePost(pwChangeInfo);
  }

}
