import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs/index';
import {TokenService} from './token.service';
import {catchError, map} from 'rxjs/internal/operators';
import {select} from '@angular-redux/store';
import {AuthenticationModel} from '../model/authentication-model';

@Injectable()
export class ServiceRefTokenInterceptor {
  @select('authenticationState') state$: Observable<any>;
  auth: AuthenticationModel;

  private tokenService: TokenService;

  constructor(private injector: Injector) {
    this.state$.subscribe(stState => {
      this.auth = stState.authenData;

    });
  }

  getToken(req: HttpRequest<any>): string {

    const token: string = this.auth.authToken;
    return 'Bearer ' + token;
  }

  authenticateRequest(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      headers: req.headers.append('Authorization', this.getToken(req)).set('Content-Type', 'application/json')
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.tokenService = this.injector.get(TokenService);
    const authReq = this.authenticateRequest(req);
    return next.handle(authReq).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        return event;
      }
      if (event instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('xxxxxx An error occurred:', event.message);
      }
    }));

  }
}

