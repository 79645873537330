import {Component, EventEmitter,  OnInit, Output} from '@angular/core';


declare var $: any;

@Component({
  selector: 'appbase-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  @Output() change: EventEmitter<string> = new EventEmitter<string>();

  public titel:string;
  public text:string;

  public retYes:string="YES";
  public retNo:string="NO";
  public retAbort:string="ABORT";
  public retOk:string="OK";

  public defaultYes:string="Ja";
  public defaultNo:string="Nein";
  public defaultAbort:string="Abbrechen";
  public defaultOk:string="Ok";

  public showAbortLabel:boolean=false;
  public showNoLabel:boolean=false;
  public showYesLabel:boolean=false;
  public showOkLabel:boolean=false;


  constructor() {
    $('.confirm.modal').remove();
 
  }

  ngOnInit() {


  }
  public show(){

    $(document).ready(function () {

      $('.confirm.modal').modal('setting', 'closable', false)
      $('.confirm.modal').modal( 'show refresh');

    });
  }


  public  yesFunc( ){

    this.change.emit(this.retYes);

  }

  public  noFunc( ){

    this.change.emit(this.retNo);

  }

  public  abortFunc( ){

    this.change.emit(this.retAbort);

  }

  public  okFunc( ){

    this.change.emit(this.retOk);

  }
}
