import {combineReducers} from 'redux-seamless-immutable'; // this is NOT import { combineReducers } from 'redux';
import {routerReducer} from '@angular-redux/router';
import {UserInformationReducer} from './UserInformationReducer'
import {AuthenticationReducer} from './authenticationReducer'
import {MenueReducer} from './menueReducer'

export const rootReducer = combineReducers({

  userInformationState: UserInformationReducer,
  authenticationState: AuthenticationReducer,
  menueState: MenueReducer,
  routerState: routerReducer

});
