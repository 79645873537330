import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {PwchangeService} from '../service/pwchange.service';
import {PwchangeFactory} from '../factory/pwchange-factory';
import {PasswortAendernFormErrorMessage} from './errormessage';
import {PasswordChangeModell} from '../model/password-change-modell';
import {throwError} from 'rxjs/index';

import {Router} from '@angular/router';
import {catchError} from 'rxjs/internal/operators';

@Component({
  selector: 'appbase-passwort-aendern',
  templateUrl: './passwort-aendern.component.html',
  styleUrls: ['./passwort-aendern.component.css']
})

export class PasswortAendernComponent implements OnInit {
  pwInfo: PasswordChangeModell;
  @ViewChild('changeForm') changeForm: NgForm;
  public errors: { [key: string]: string } = {};

  constructor(private pws: PwchangeService, private router: Router) {
    this.pwInfo = PwchangeFactory.emtpy();
  }

  ngOnInit() {
    this.changeForm.statusChanges.subscribe(() => this.upDateErrorMessages());
  }

  changePassword() {

    this.pws.pwChange(this.pwInfo).pipe(catchError(this.handleErrorObservable.bind(this))).subscribe(this.handleValidResult.bind(this));
  }

  upDateErrorMessages() {
    this.errors = {};
    for (const message of PasswortAendernFormErrorMessage) {
      const control = this.changeForm.form.get(message.forControl);
      if (control && control.dirty && control.invalid &&
        control.errors[message.forValidator] && !this.errors[message.forControl]) {
        this.errors[message.forControl] = message.text;
      }
      if (control && control['minlength'] &&
        control.errors[message.forValidator] && !this.errors[message.forControl]) {
        this.errors[message.forControl] = message.text;
      }
    }
  }

  private handleErrorObservable(error: Response | any) {
    // todo: was macht die Komponente im Fehlerfall
    return throwError(error.message || error);
  }

  private handleValidResult(dat: any) {
    this.pwInfo.fromObject(dat);
    if (this.pwInfo.errorcode && this.pwInfo.errorcode.length > 0) {

    } else {

      this.router.navigate(['/home']);
    }
  }

  private errortext(errorcode: string) {
    if ('NO_OLD_PW_MATCH' === errorcode) {
      return 'Aktuelles Passwort ist falsch!';
    }
    if ('NO_NEW_PW_MATCH' === errorcode) {
      return 'Die beiden neuen Passwörter stimmen nicht überein!';
    }
    if ('WEAK_PW' === errorcode) {
      return 'Das Password ist zu schwach. Mindestens 8 Zeichen mit Klein-/Großbuchstaben, Sonderzeichen und Zahl!';
    }
    if ('OLD_NEW_PW_MATCH' === errorcode) {
      return 'Das alte und das neue Passwort dürfen nicht identisch sein!';
    }
    return 'Passwort konnte nicht geändert werden - ' + errorcode;
  }
}



