import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'betrag'
})
export class BetragPipe implements PipeTransform {

  public transform(value: any, args?: any): any {
    if (null == value) {
      return "";
    }
    let text: string = value.toString();
    let minus : string = "";
    if(text.startsWith("-")) {
      minus = "-";
      text = text.substring(1);
    }
    const teile: string[] = text.split(".");
    if (teile.length === 1) {
      return minus + this.tausender(teile[0]) + ",00€";
    }
    if (teile[1].length === 1) {
      return minus + this.tausender(teile[0]) + ',' + teile[1] + "0€";
    }
    else {
      return minus + this.tausender(teile[0]) + ',' + teile[1] + "€";
    }
  }

  private tausender(zahl: string): string {
    if (zahl.startsWith("-")) {
      return "-" + this.tausender(zahl.substring(1))
    }
// Info: Die '' sind zwei Hochkommas
    if (zahl.length > 3) {
      return this.tausender(zahl.substring(0, zahl.length - 3)) + "." + zahl.substring(zahl.length - 3);
    } else {
      return zahl;
    }
  }

}
