import {Injectable} from '@angular/core';
import {LoginFactory} from '../factory/login-factory';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/index';
import {catchError} from 'rxjs/internal/operators';
import {select} from '@angular-redux/store';
import {AuthenticationModel} from '../model/authentication-model';

@Injectable()
export class TokenService {
  @select('authenticationState') state$: Observable<any>;
  auth: AuthenticationModel;

  constructor(private httpClient: HttpClient, private router: Router) {

    this.state$.subscribe(stState => this.auth = stState);
  }

  // public obtainNewAuthToken(): Observable<any> {
 //   const logToken = LoginFactory.emtpy();
  //  logToken.refreshToken = sessionStorage.getItem('refreshToken');
  //  const objectToSenda = JSON.stringify(logToken);
  //  const obs: Observable<Object> = this.httpClient.post(`${environment.apiUrl}/ref_token.php`, objectToSenda);
  //  obs.pipe(catchError(this.handleRefreshError.bind(this))).subscribe(this.refreshToken.bind(this));
   // return obs;
  // }

  private handleRefreshError(error: Response | any) {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  private refreshToken(dat: any) {
    sessionStorage.setItem('authToken', dat.AUTHTOKEN);
  }

}
