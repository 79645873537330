import {Injectable} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../interfaces/iapp-state';
import {EnumAction, EnumAuthenticationAction} from '../enums/ssp-actions.enum';
import {AuthenticationModel} from '../appbase/model/authentication-model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationActionService {

  constructor(private ngRedux: NgRedux<IAppState>) {


  }

  login(obj: AuthenticationModel) {
    this.ngRedux.dispatch({type: EnumAuthenticationAction.login, payload: obj});
  }

  logout() {
    this.ngRedux.dispatch({
      type: EnumAuthenticationAction.login,
      payload: new AuthenticationModel('', '', '', '', false)
    });
  }

}
