export enum EnumAction {
  Data_loading = 'Data_loading',
  UserInformation_ErrorLaunch = 'UserInformation_ErrorLaunch',
  UserInformation_Warning_Launch = 'UserInformation_Warning_Launch',
  UserInformation_Reset = 'UserInformation_Reset',
  UserInformation_Information_Launch = 'UserInformation_Information_Launch',
  UserInformation_DISPLAY_Wait_Start_Launch = 'UserInformation_DISPLAY_Wait_Start_Launch',
  UserInformation_DISPLAY_Wait_Stop_Launch = 'UserInformation_DISPLAY_Wait_Stop_Launch',
  ShowMenue = 'ShowMenue',


}

export enum EnumAuthenticationAction {
  login = 'login',
  logout = 'logout'

}
