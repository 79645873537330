import {Injectable} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../interfaces/iapp-state';
import {EnumAction} from '../enums/ssp-actions.enum';


@Injectable({
  providedIn: 'root'
})
export class UserInformationActionService {

  constructor(private ngRedux: NgRedux<IAppState>) {


  }

  showWaitDisplay(serviceName: string) {
    this.resetUserInformation();
    this.ngRedux.dispatch({type: EnumAction.UserInformation_DISPLAY_Wait_Start_Launch, payload: serviceName});
  }

  stopWaitDisplay() {
    this.resetUserInformation();
    this.ngRedux.dispatch({type: EnumAction.UserInformation_DISPLAY_Wait_Stop_Launch});
  }

  showWarning(infoText: string) {
    this.resetUserInformation();
    this.ngRedux.dispatch({type: EnumAction.UserInformation_DISPLAY_Wait_Start_Launch, payload: infoText});
  }

  showError(infoText: string) {
    this.resetUserInformation();
    this.ngRedux.dispatch({type: EnumAction.UserInformation_ErrorLaunch, payload: infoText});
  }

  showInfo(infoText: string) {
    this.resetUserInformation();
    this.ngRedux.dispatch({type: EnumAction.UserInformation_Information_Launch, payload: infoText});
  }

  resetUserInformation() {
    this.ngRedux.dispatch({type: EnumAction.UserInformation_Reset});
  }

}
