import {Component, OnInit} from '@angular/core';
import {BewerberService} from '../../service/bewerber.service'
import {UserInformationComponent} from '../../appbase/user-information/user-information.component'
import {UserInformationActionService} from '../../actions/user-information-action.service'
import {ConfirmDialogServService} from '../../appbase/confirm-dialog/confirm-dialog-serv.service'
import {FormBuilder} from '@angular/forms'
import {Router} from '@angular/router'


declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  bewerbungen: any[];
  constructor(private bewServ: BewerberService, private userInfoAction: UserInformationActionService, private router: Router) {
  }

  ngOnInit() {
    this.userInfoAction.showWaitDisplay('Lade Tickets');
    this.bewServ.lesen({}).subscribe(json => this.ticketCallBack(json), error => this.handleServiceError(error));
  }


  private ticketCallBack(json: any) {

    this.userInfoAction.stopWaitDisplay();
    this.bewerbungen=json.bewerbungen;


  }


  private handleServiceError(error: any) {
    this.userInfoAction.stopWaitDisplay();
    this.userInfoAction.showError(error.message);

  }



  showBewerbung(bew_id:string) {


    this.router.navigate(['bewerbung'],{queryParams: {id:bew_id}});
  }
}
