import {Injectable} from '@angular/core';

import {IWerboHttpService} from './i-werbo-http.service';
import {Router} from '@angular/router';

import {SessionparameterService} from './sessionparameter.service';
import {BerechtigungsModell} from '../model/berechtigungs-modell';
import {AuthenticationModel} from '../model/authentication-model';

@Injectable()
export class LoginService {
  constructor(private http: IWerboHttpService, private router: Router, private berechtigungen: BerechtigungsModell,
              private sessionparameterService: SessionparameterService) {
  }

  login(userInfo: Object) {
    return this.http.loginServicePost(userInfo);
  }

  loginSSO(cto: string) {
    return this.http.ssoServicePost(cto);
  }

  isLoggedIn(): boolean {
    return sessionStorage.getItem('isLoggedIn') === 'true';
  }

  logout() {
    this.sessionparameterService.reset();
    this.berechtigungen.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }
}
