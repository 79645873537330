import {AfterViewChecked, Component, OnInit} from '@angular/core';

import {Router} from '@angular/router';

import {BerechtigungsModell} from '../../appbase/model/berechtigungs-modell';


import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {MenueState, UserInformationState} from '../../interfaces/iapp-state';
import {MenueSteuerungActionService} from '../../actions/menue-steuerung-action.service';
import {AuthenticationActionService} from '../../actions/authentication-action.service';
import {environment} from '../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-menue',
  templateUrl: './menue.component.html',
  styleUrls: ['./menue.component.css']
})
export class MenueComponent implements AfterViewChecked, OnInit {
  @select() menueState$: Observable<UserInformationState>;
  protected mState: MenueState;
  version: string;

  constructor(public berechtigungen: BerechtigungsModell,
              private router: Router, private menSteuerAction: MenueSteuerungActionService, private authAct: AuthenticationActionService) {
    this.version = environment.version;
  }

  logout() {
    this.authAct.logout();
    this.router.navigate(['/login']);
    // this.first = true;

  }

  public ngOnInit() {
    this.menueState$.subscribe(menueState => this.stateManagement(menueState));

  }

  public ngAfterViewChecked() {
   
  }

  stateManagement(mState: UserInformationState) {
    this.mState = mState;

  }



  public selectActivPanel() {

   
  }

}
