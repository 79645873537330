import {PasswordChangeModell} from '../model/password-change-modell';
import {FactoryBase} from './factory-base';

export class PwchangeFactory extends FactoryBase {
  static emtpy() {
    return new PasswordChangeModell();

  }

  static fromObject(rawLogin: any): PasswordChangeModell {
    const lm = new PasswordChangeModell();
    lm.oldPasswort = rawLogin.OLD_PASSWORD;
    lm.newPasswort = rawLogin.NEW_PASSWORD;
    lm.newRepeatPasswort = rawLogin.NEW_REPEAT_PASSWORD;

    return lm;
  }

  static toObject(mod: PasswordChangeModell, rawLogin: any) {

    mod.oldPasswort = rawLogin.OLD_PASSWORD;
    mod.newPasswort = rawLogin.NEW_PASSWORD;
    mod.newRepeatPasswort = rawLogin.NEW_REPEAT_PASSWORD;

  }
}
