export class AuthenticationModel {

  public authToken: string;
  public userName: string;
  public version: string;
  public expired: string;
  public isLoggedIn: boolean;


  constructor(authToken: string, userName: string, version: string, expired: string, isLoggedIn: boolean) {
    this.authToken = authToken;
    this.userName = userName;
    this.version = version;
    this.expired = expired;
    this.isLoggedIn = isLoggedIn;
  }

  static toObject(rawLogin: any, isLoggedIn: boolean): AuthenticationModel {
    return new AuthenticationModel(rawLogin.authToken, rawLogin.userName, rawLogin.version, rawLogin.expired, isLoggedIn);


  }

}



