import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormBuilder, FormsModule} from '@angular/forms';
import {LoginService} from './service/login.service';
import {ServiceRefTokenInterceptor} from './service/service-ref-token-interceptor';
import {IWerboHttpService} from './service/i-werbo-http.service';
import {PwchangeService} from './service/pwchange.service';
import {BerechtigungsModell} from './model/berechtigungs-modell';
import {BetragPipe} from './pipe/betrag.pipe';
import {Queryparameter} from './service/queryparameter';
import {TokenService} from './service/token.service';
import {SessionparameterService} from './service/sessionparameter.service';
import {AuthGuard} from './guard/auth.guard';
import {RoleGuard} from './guard/role.guard';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {BaseComponent} from './base/base.component';
import {PasswortAendernComponent} from './passwort-aendern/passwort-aendern.component';
import {DateValueAccessorDirective} from './directive/date-value-accessor.directive';
import {ZeitpunktPipe} from './pipe/zeitpunkt.pipe';
import {DatumPipe} from './pipe/datum.pipe';
import {StringNumberPipe} from './pipe/string-number.pipe';
import {RouterModule} from '@angular/router';
import {SaveiframePipe} from './pipe/saveiframe.pipe';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { ElemDateinputComponent } from './elements/elem-dateinput/elem-dateinput.component';


@NgModule({

  declarations: [
    LoginComponent,
    BaseComponent,
    PasswortAendernComponent,
    ConfirmDialogComponent,
    DateValueAccessorDirective,
    BetragPipe,
    ZeitpunktPipe,
    DatumPipe,
    StringNumberPipe,
    SaveiframePipe,
    SafeHtmlPipe,
    ElemDateinputComponent,

  ],
  imports: [
    CommonModule, BrowserModule, HttpClientModule, FormsModule, RouterModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ServiceRefTokenInterceptor, multi: true},
    IWerboHttpService, PwchangeService, BerechtigungsModell,
    Queryparameter, TokenService, FormBuilder, LoginService,
    SessionparameterService, AuthGuard, RoleGuard, BerechtigungsModell, ConfirmDialogComponent
  ],

  exports: [
    LoginComponent,
    BaseComponent,
    PasswortAendernComponent,
    ConfirmDialogComponent,
    DateValueAccessorDirective,
    BetragPipe,
    ZeitpunktPipe,
    DatumPipe,
    SafeHtmlPipe,
    SaveiframePipe,
    StringNumberPipe,
    ElemDateinputComponent,
  ],
  entryComponents: [ConfirmDialogComponent]
})
export class AppbaseModule {
}
