import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router'
import {ConfirmDialogServService} from '../../appbase/confirm-dialog/confirm-dialog-serv.service'
import {FormBuilder} from '@angular/forms'
import {BewerberService} from '../../service/bewerber.service'
import {UserInformationActionService} from '../../actions/user-information-action.service'
import {DownloadService} from '../../appbase/download/download.service'

@Component({
  selector: 'app-bewerbung',
  templateUrl: './bewerbung.component.html',
  styleUrls: ['./bewerbung.component.css']
})
export class BewerbungComponent implements OnInit {
  public id: String;
  public bewerbung: any;

  constructor(private userInfoAction: UserInformationActionService, private cnf: ConfirmDialogServService,
              private bewServ: BewerberService, private formBuilder: FormBuilder, private router: Router,
              private route: ActivatedRoute, private dwServ: DownloadService) {
    this.bewerbung = {};
  }

  ngOnInit() {


    this.route.queryParams.subscribe((params: Params) => {
      this.id = params['id']
      const obj = {id: this.id};


      this.userInfoAction.stopWaitDisplay();
      this.bewServ.leseBewerbung(obj).subscribe(json => this.bewerbungCallback(json), error => this.handleServiceError(error));
    });

  }


  private bewerbungCallback(json: any) {


    this.userInfoAction.stopWaitDisplay();
    this.bewerbung = json;


  }


  private handleServiceError(error: any) {
    this.userInfoAction.stopWaitDisplay();
    this.userInfoAction.showError(error.message);

  }

  showDokument(bew_id: string) {

    const obj = {id: bew_id};
    this.userInfoAction.stopWaitDisplay();
    this.bewServ.leseDokument(obj).subscribe(json => this.dokumentCallback(json), error => this.handleServiceError(error));
  }

  private dokumentCallback(json: any) {

    this.dwServ.download(json.filetype, json.filename, json.filecontent);

  }
}
