import {Injectable} from '@angular/core';
import {ISessionParameterService} from './i-session-parameter-service';

@Injectable()
export class SessionparameterService {
  private registrierteServices: Array<ISessionParameterService>;

  constructor() {
    this.registrierteServices = [];
  }

  register(iSessionParameterService: ISessionParameterService) {
    this.registrierteServices.push(iSessionParameterService);
  }
  reset() {
    for (const entry of this.registrierteServices) {
      entry.reset();
    }
    this.registrierteServices = [];
  }
}
