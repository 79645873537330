export class ConfirmEvent {
  public obj:any=null;
  public isOk:boolean;
  public isAbort:boolean;
  public isNo:boolean;
  public isYes:boolean;



}
