import {Injectable} from '@angular/core';

Injectable();

export class BerechtigungsModell {
  public darfPasswortAendern: boolean;
  private berechtigungen: Set<string>;
  constructor() {
    this.clear();
  }

  public clear() {
    this.darfPasswortAendern = false;
    this.berechtigungen = new Set<string>();
  }

  public setzeBerechtigung(berechtigungsCsv: string) {


    this.berechtigungen = new Set(berechtigungsCsv.split(';'));
  }
  public hatBerechtigung(name: string) {
    return this.berechtigungen.has(name);
  }

   public darfAnwendungBenutzen() {
    return this.berechtigungen.has('ANWENDUNG_BENUTZEN');
  }
}
