import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationModel} from '../model/authentication-model';
import {LoginFactory} from '../factory/login-factory';
import {LoginService} from '../service/login.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Queryparameter} from '../service/queryparameter';
import {BerechtigungsModell} from '../model/berechtigungs-modell';
import {SessionparameterService} from '../service/sessionparameter.service';
import {Subscription} from 'rxjs';
import {AuthenticationActionService} from '../../actions/authentication-action.service';
import {UserInformationActionService} from '../../actions/user-information-action.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']

})
export class LoginComponent implements OnInit, OnDestroy {

  showLoginDialog: Boolean;
  private sub: Subscription;
  public loginName: string;
  public password: string;
  public version: string;

  constructor(private loginService: LoginService, private route: ActivatedRoute,
              private queryparameter: Queryparameter, private router: Router, private berechtigungen: BerechtigungsModell,
              private  sessionparameterService: SessionparameterService, private authAction: AuthenticationActionService,
              private userInfoAction: UserInformationActionService) {

    this.loginName = '';
    this.password = '';
    this.showLoginDialog = true;
    this.version = environment.version;
  }

  ngOnInit() {
    this.sessionparameterService.reset();
    this.berechtigungen.clear();

    this.sub = this.route.queryParams.subscribe((params: Params) => {

      this.queryparameter.params = params;

      const cto: Object = params['CTO'];

      if (cto > '') {
        this.showLoginDialog = false;
        this.loginService.loginSSO(cto.toString()).subscribe(json => this.loginCallback(json), error => this.handleServiceError(error));
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  submitForm() {

    this.userInfoAction.showWaitDisplay('Login');
    this.loginService.login({loginName: this.loginName, password: this.password}).subscribe(json => this.loginCallback(json),
      error => this.handleServiceError(error));
  }

  private loginCallback(json: any) {

    this.authAction.login(AuthenticationModel.toObject(json, true));
    this.berechtigungen.setzeBerechtigung(json.berechtigungen);
    this.berechtigungen.darfPasswortAendern = true;
    this.showLoginDialog = false;
    this.userInfoAction.resetUserInformation();
    this.router.navigate(['home']);
    this.userInfoAction.stopWaitDisplay();
    /*
    if (json.route) {
      this.router.navigate([json.route]);
    } else {
      if (this.userInformation.renewPw) {
        this.router.navigate(['/changepassword']);
      } else {
        this.router.navigate(['/home']);
      }
    }
    */
  }


  private handleServiceError(error: any) {
    this.userInfoAction.stopWaitDisplay();

    if (error.error.message === 'LOGIN_FAILED') {
      this.userInfoAction.showError('Passwort oder Benutzername falsch!!!');
    } else {
      this.userInfoAction.showError('Der Service steht zur Zeit nicht zur Verfügung' +
        '<br>Bitte versuchen Sie es später wieder');
    }

    this.authAction.logout();

  }
}
