import { Injectable } from '@angular/core';
import {IWerboHttpService} from '../appbase/service/i-werbo-http.service'

@Injectable({
  providedIn: 'root'
})
export class BewerberService {

  constructor(private http: IWerboHttpService) { }


  bewerben(obj: Object) {
    return this.http.bewerbenPost(obj);
  }

  lesen(obj: Object) {
    return this.http.lesen(obj);
  }
  leseBewerbung(obj: Object) {
    return this.http.leseBewerbung(obj);
  }
  leseDokument(obj: Object) {
    return this.http.leseDokument(obj);
  }
}
