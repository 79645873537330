import {Injectable} from '@angular/core';

@Injectable()

export class IwBaseServices {

  constructor() {
  }

}
