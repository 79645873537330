import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {UploadFile} from '../../models/UploadFile'
import {ConfirmDialogServService} from '../../appbase/confirm-dialog/confirm-dialog-serv.service'
import {FormBuilder} from '@angular/forms'
import {ActivatedRoute, Params, Router} from '@angular/router'
import {Bewerberdaten} from '../../models/bewerberdaten'
import {BewerberService} from '../../service/bewerber.service'
import {UserInformationActionService} from '../../actions/user-information-action.service'

declare var $: any;

@Component({
  selector: 'app-bewerben',
  templateUrl: './bewerben.component.html',
  styleUrls: ['./bewerben.component.css']
})
export class BewerbenComponent implements OnInit, AfterViewInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  isDatenSchutzZugestimmt: string;
  beDaten = new Bewerberdaten();
  fileCounter = 0;
  files: any[];
  stelle = '';
  stellenbezeichnung = '';
  showDatenschutzMenue = false;

  constructor(private cnf: ConfirmDialogServService, private route: ActivatedRoute, private bwService: BewerberService, private userInfoAction: UserInformationActionService) {

    this.route.queryParams.subscribe((params: Params) => {
      this.stelle = params['id'];
      this.stellenbezeichnung = params['bez'];
      this.newbeDaten();
    });
  }

  ngOnInit() {
 
  }

  ngAfterViewInit() {


  }

  closeDatneschutz() {
    $('#datenschutz').modal('hide');
    return false;
  }

  showDatenschutz() {
    $('#datenschutz').modal({
      allowMiltiple: true,
    }).modal('show');
    return false;
  }


  submit() {
    let dat: object;
    this.beDaten.dokumente = this.files;
    this.beDaten.stelle = this.stelle;

    this.cnf.open('Achtung', 'Möchten Sie die Bewerbung absenden?', true, true, false, false);

    this.cnf.callbackEvent.subscribe(val => {
      if (val.isYes === true) {
        this.userInfoAction.showWaitDisplay("Ihre Bewerbung wird vearbeitet")
        this.bwService.bewerben(this.beDaten).subscribe(json => this.bewerbenCallBack(json), error => this.handleServiceError(error));

      }
    });
  }

  private bewerbenCallBack(json: any) {
    this.userInfoAction.stopWaitDisplay();
    if (json.Error == null) {
      this.userInfoAction.showInfo(' Herzlichen Dank für Ihre Bewerbung und Ihr Interesse an einer Beschäftigung bei der iWerbo GmbH.<br><br>\n' +
        'Wir werden Ihre Bewerbung eingehend prüfen und uns mit Ihnen in Verbindung setzen.');
     this.reset();
    } else {
      this.userInfoAction.showError('Leider steht das Bewerbersystem nicht zur Verfügung. Bitte versuchen Sie es später wieder');
    }

  }


  private handleServiceError(error: any) {
    this.userInfoAction.stopWaitDisplay();
    this.userInfoAction.showError(error.message);

  }

  reset() {
    this.newbeDaten();
    this.resetFile();

  }

  newbeDaten() {
    this.beDaten = new Bewerberdaten();
    this.beDaten.info = 'Was bedeutet für Sie agiles Arbeiten?\n' + '\n' +
      'Was würde sich für das Team verändern, wenn Sie dazugehören?\n' + '\n' +
      'Was müsste passieren, dass Sie nicht mehr in einem Unternehmen arbeiten möchten?\n';
    this.files = [];
    this.beDaten.dokumente = this.files;
  }

  resetFile() {

    this.fileInput.nativeElement.value = '';
    return false;
  }

  deleteFile(id: number) {

    this.cnf.open('Achtung', 'Möchten Sie die Datei löschen?', true, true, false, false);

    this.cnf.callbackEvent.subscribe(val => {
      if (val.isYes === true) {
        this.files = this.files.filter(obj => obj.id !== id);
      }
    });
    return false;

  }

  onFileChange(event) {
    const reader = new FileReader();


    if (event.target.files && event.target.files.length > 0) {

      const fi = new UploadFile();
      this.fileCounter++;

      fi.id = this.fileCounter
      const file = event.target.files[0];
      fi.filename = event.target.files[0].name;
      reader.readAsDataURL(file);

      reader.onload = () => {

        fi.filecontent = reader.result.split(',')[1];
        fi.filetype = reader.result.split(',')[0].split(';')[0].split(':')[1];
        this.files = this.files.concat(fi);
        this.resetFile();
      };
    }
  }

}
