import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpHandler, HttpHeaders} from '@angular/common/http';

@Injectable()
export class IWerboHttpService extends HttpClient {
  private restUrl: string = environment.restUrl;

  constructor(handler: HttpHandler) {
    super(handler);
  }

  public loginServicePost(datObj: object): Observable<Object> {
    const objectToSend = JSON.stringify(datObj);
    const url: string = this.restUrl + '/login';
    return super.post(url, objectToSend);
  }

  public ssoServicePost(ssoToken: string): Observable<Object> {
    const url: string = this.restUrl + '/sso';
    return super.post(url, {ssoToken: ssoToken});
  }

  public changePasswortServicePost(datObj: object): Observable<Object> {
    const objectToSend = JSON.stringify(datObj);
    const url: string = this.restUrl + '/changepw';
    return super.post(url, objectToSend);
  }
  public bewerbenPost(datObj: object): Observable<Object> {
    const objectToSend = JSON.stringify(datObj);
    const url: string = this.restUrl + '/bewerben';
    return super.post(url, objectToSend);
  }
  public lesen(datObj: object): Observable<Object> {
    const objectToSend = JSON.stringify(datObj);
    const url: string = this.restUrl + '/lesen';
    return super.post(url, objectToSend);
  }
  public leseBewerbung(datObj: object): Observable<Object> {
    const objectToSend = JSON.stringify(datObj);
    const url: string = this.restUrl + '/lesebewerbung';
    return super.post(url, objectToSend);
  }
  public leseDokument(datObj: object): Observable<Object> {
    const objectToSend = JSON.stringify(datObj);
    const url: string = this.restUrl + '/lesedokument';
    return super.post(url, objectToSend);
  }



}
