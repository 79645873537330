import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {BerechtigungsModell} from '../model/berechtigungs-modell';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit, OnDestroy {
  protected paramSubscribe: any;
  protected isServiceRunning: Boolean;

  constructor(protected router: Router, protected berechtigungsModell: BerechtigungsModell) {
    this.serviceEnded();
  }

  protected handleServiceErrorIntern(error: Response | any): Boolean {
    return false;
  }

  protected handleServiceErrorInternal(error: Response | any) {
    if (!this.handleServiceErrorIntern(error)) {

    }
  }

  public handleServiceError(error: Response | any) {
    console.log('errrrrror ' + error.status);
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        // nix tun: wird schon im Interceptor behandelt
      } else {
        if (error.status === 500) {
          this.handleServiceErrorInternal(error);
        } else {
          if (error.status < 900) {
            this.handleRefreshError();
          }
          this.handleServiceErrorInternal(error);
        }
      }
    }
    this.serviceEnded();
  }

  ngOnInit(): void {

  }

  private handleRefreshError() {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  protected hatBerechtigung(name: string) {
    return this.berechtigungsModell.hatBerechtigung(name);
  }

  protected runService(serviceName: string) {
    this.isServiceRunning = true;

  }

  protected serviceEnded() {
    this.isServiceRunning = false;

  }

  protected isNegativ(val: any) {
    if (null == val) {
      return false;
    }
    return (val.toString().startsWith('-'));
  }

  ngOnDestroy(): void {
    if (this.paramSubscribe != null) {
      this.paramSubscribe.unsubscribe();
    }
  }
}
