import {Component, OnInit} from '@angular/core';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {UserInformationState} from '../../interfaces/iapp-state';
import {UserInformationActionService} from '../../actions/user-information-action.service';

declare var $: any;

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.css']
})
export class UserInformationComponent implements OnInit {
  @select() userInformationState$: Observable<UserInformationState>;


  protected isError: boolean;
  protected response: Response;
  protected isLoading: boolean;
  protected isWarning: boolean;
  protected isInformation: boolean;
  protected displayText: string;
  protected serviceName: string;


  constructor(private usAction: UserInformationActionService) {
    this.isError = false;
    this.isInformation = false;
    this.isWarning = false;
    this.isLoading = false;
    this.displayText = '';
    this.serviceName = '';


  }


  ngOnInit() {
    this.userInformationState$.subscribe(usiState => this.stateManagement(usiState));

  }


  stateManagement(usiState: UserInformationState) {

    this.isError = usiState.isError;
    this.response = usiState.response;
    this.isWarning = usiState.isWarning;
    this.isInformation = usiState.isInformation;
    this.displayText = usiState.displayText;
    this.isLoading = usiState.isLoading;
    this.serviceName = usiState.serviceName;

    if (this.isError || this.isWarning || this.isInformation) {
      $('#modalUIMessage').modal({
        dimmerSettings: {opacity: 0},
        allowMiltiple: true,
      }).modal('show');

    }

  }

}
