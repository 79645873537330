import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.css']
})
export class DatenschutzComponent implements OnInit {
  @Input()showMenue=true;
  constructor() { }

  ngOnInit() {
  }

}
