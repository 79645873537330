import * as si from 'seamless-immutable';

import {UserInformationState} from '../interfaces/iapp-state';
import {IActionPayload} from '../interfaces/iaction-payload';
import {EnumAction} from '../enums/ssp-actions.enum';


const INITIAL_USERINFORMATION_STATE: UserInformationState = si.from({
  isError: false,
  isLoading: false,
  isWarning: false,
  isInformation: false,
  displayText: '',
  serviceName: ''

});

export function UserInformationReducer(state: UserInformationState = INITIAL_USERINFORMATION_STATE,
                                       action: IActionPayload<UserInformationState>): UserInformationState {

  switch (action.type) {

    case EnumAction.UserInformation_ErrorLaunch:
      return state
        .set('isError', true)
        .set('displayText', action.payload)
        .set('isLoading', false)
        .set('serviceName', '');
    case EnumAction.UserInformation_Warning_Launch:
      return state
        .set('isWarning', true)
        .set('displayText', action.payload)
        .set('isLoading', false)
        .set('serviceName', '');
    case EnumAction.UserInformation_Information_Launch:
      return state
        .set('isInformation', true)
        .set('displayText', action.payload)
        .set('isLoading', false)
        .set('serviceName', '');
    case EnumAction.UserInformation_DISPLAY_Wait_Start_Launch:
      return state
        .set('isLoading', true)
        .set('serviceName', action.payload);
    case EnumAction.UserInformation_DISPLAY_Wait_Stop_Launch:
      return state
        .set('isLoading', false)
        .set('serviceName', '');
    case EnumAction.UserInformation_Reset:
      return state
        .set('isError', false)
        .set('isWarning', false)
        .set('isInformation', false)
        .set('isInformation', false)
        .set('displayText', '');
    default:
      return state;
  }

}
