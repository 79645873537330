export class PasswordChangeModell {

  public oldPasswort: string;
  public newPasswort: string;
  public newRepeatPasswort: string;
  public errorcode: string;

  public fromObject(rawLogin: any) {
    this.oldPasswort = rawLogin.old_password;
    this.newPasswort = rawLogin.new_password;
    this.newRepeatPasswort = rawLogin.new_repeat_password;
    this.errorcode = rawLogin.errorcode;
  }
}
