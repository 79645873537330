import {UploadFile} from './UploadFile'

export class Bewerberdaten {
  public stelle = '';
  public anrede = '';
  public vorname = '';
  public name = '';
  public strasse = ''
  public plz = '';
  public ort = '';
  public email = '';
  public telefon = '';
  public info = '';
  public gehalt = '';
  public eintrittstermin = '';
  public dokumente: UploadFile[];
}
