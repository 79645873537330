import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {BerechtigungsModell} from '../model/berechtigungs-modell';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(private berechtigungen: BerechtigungsModell) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const roles: Array<string> = route.data.roles;
    if (roles.length > 0) {
      for (let role of roles) {
        if (this.berechtigungen.hatBerechtigung(role)) {
          return true;
        }
      }
    }
    return false;
  }
}
