export class Errormessage {
  constructor(public forControl: string,
              public forValidator: string,
              public text: string) {
  }

}

export const PasswortAendernFormErrorMessage = [
  new Errormessage('oldPasswort', 'required', 'Bitte geben SIe das alte Passwort ein'),
  new Errormessage('oldPasswort', 'minlength', 'Das Passwort muss 8 Zeichen betragen'),
  new Errormessage('newpassword', 'minlength', 'Das Passwort muss 8 Zeichen betragen'),
  new Errormessage('newRepeatPasswort', 'minlength', 'Das Passwort muss 8 Zeichen betragen')

];



