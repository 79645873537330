import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  private allItems: {};

  constructor() {
  }

  download(blobtyp: string, filename: string, data: string) {


    const linkSource = 'data:' + blobtyp  + ';base64,' +  data +"\n"

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(linkSource, filename);
    } else {
      const a = document.createElement('a');
      a.href =linkSource;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

  }
}
