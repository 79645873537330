import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './appbase/login/login.component';
import {HomeComponent} from './loginbereich/home/home.component';
import {RoleGuard} from './appbase/guard/role.guard';
import {PasswortAendernComponent} from './appbase/passwort-aendern/passwort-aendern.component';
import {KarriereComponent} from './oeffentlich/karriere/karriere.component'
import {DatenschutzComponent} from './oeffentlich/datenschutz/datenschutz.component'
import {ImpressumComponent} from './oeffentlich/impressum/impressum.component'
import {WebentwicklerComponent} from './oeffentlich/webentwickler/webentwickler.component'
import {BewerbenComponent} from './oeffentlich/bewerben/bewerben.component'
import {BewerbungComponent} from './loginbereich/bewerbung/bewerbung.component'


const routes: Routes = [
  {path: '', redirectTo: 'karriere', pathMatch: 'full'},
  {path: 'karriere', component: KarriereComponent, pathMatch: 'full'},
  {path: 'webentwickler', component: WebentwicklerComponent, pathMatch: 'full'},
  {path: 'bewerben', component: BewerbenComponent, pathMatch: 'full'},
  {path: 'datenschutz', component: DatenschutzComponent, pathMatch: 'full'},
  {path: 'impressum', component: ImpressumComponent, pathMatch: 'full'},
  {path: 'login', component: LoginComponent, pathMatch: 'full'},
  {path: 'login/:CTO', component: LoginComponent, data: {name: 'login', show: 'false'}, pathMatch: 'full'},
  {path: 'chanhgepwd', component: PasswortAendernComponent, data: {roles: ['BEARBEITUNG']}, pathMatch: 'full'},
  {path: 'bewerbung', component: BewerbungComponent, canActivate: [RoleGuard], data: {roles: ['BEARBEITUNG']}},
  {path: 'home', component: HomeComponent, canActivate: [RoleGuard], data: {roles: ['BEARBEITUNG']}, pathMatch: 'full'},
  {path: '**',  redirectTo: 'karriere', pathMatch: 'full'},

];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);

export class AppRoutingModule {
}
