import {Injectable} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../interfaces/iapp-state';
import {EnumAction} from '../enums/ssp-actions.enum';

@Injectable({
  providedIn: 'root'
})
export class MenueSteuerungActionService {

  constructor(private ngRedux: NgRedux<IAppState>) {
  }

  showMenue() {
    this.ngRedux.dispatch({type: EnumAction.ShowMenue});
  }


}

