import * as si from 'seamless-immutable';

import {AuthenticationState, UserInformationState} from '../interfaces/iapp-state';
import {IActionPayload} from '../interfaces/iaction-payload';
import {EnumAction, EnumAuthenticationAction} from '../enums/ssp-actions.enum';
import {AuthenticationModel} from '../appbase/model/authentication-model';


const INITIAL_AUTHENTICATION_STATE: AuthenticationState = si.from({
  authenData: new AuthenticationModel('', '', '', '', false)

})
;

export function AuthenticationReducer(state: AuthenticationState = INITIAL_AUTHENTICATION_STATE,
                                      action: IActionPayload<AuthenticationState>): AuthenticationState {

  switch (action.type) {

    case EnumAuthenticationAction.login:
      return state
        .set('authenData', action.payload);
    case EnumAuthenticationAction.logout:
      return state;

    default:
      return state;
  }

}
